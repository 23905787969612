import React from "react";
import styled from "styled-components";

const HeroContainer = styled.div`
    position: relative;
    color: #fff;
    max-width: 1390px;
    max-height: 429px;
    overflow: hidden;
    margin: 84px auto 0;
    font-size: 24px;
    text-align: center;

    @media screen and (max-width: 1440px) {
        max-width: 1120px;
        font-size: 22px;
    }

    @media screen and (max-width: 400px) {
        font-size: 18px;
    }
`;

const HeroContentContainer = styled.div`
    h2 {
        font-size: 1.2em;
        line-height: 56px;
        margin-bottom: 32px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 0 1 rem;
    }

    p {
        font-size: 0.66em;
        line-height: 24px;
        padding: 0 24px;
        font-weight: 400;
    }

    @media screen and (max-width: 1440px) {
        h2 {
            margin-bottom: 24px;
        }
    }

    @media screen and (max-width: 400px) {
        h2 {
            margin-bottom: 16px;
        }
    }
`;

const Hero = ({ content }: { content: string }) => {
    // console.log(content);
    return (
        <HeroContainer>
            <HeroContentContainer dangerouslySetInnerHTML={{ __html: content }} />
        </HeroContainer>
    );
};

export default Hero;
