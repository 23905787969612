import { Index } from "flexsearch-ts";
import { graphql, Link, useStaticQuery } from "gatsby";
import { pick } from "lodash";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";

import allnyu_map from "@/images/allnyu_map.png";

type IndexableDocument = Record<string, unknown>;
type Store = Record<string, unknown>;

const SearchFunctionSetup = () => {
    const data = useStaticQuery(graphql`
        {
            allSitePage {
                nodes {
                    id
                    path
                    pageContext
                }
            }
        }
    `);

    // Create index
    // const indexName = "pages";
    const documentRef = "id";
    const documentIndexKeys = ["title", "body"];

    const documentStore = ["id", "path", "title"];

    const normalizedData = data.allSitePage.nodes.map(
        (node: { id: string; path: string; pageContext: { title: string; content: string } }) => ({
            id: node.id,
            path: node.path,
            title: node.pageContext?.title || node.id,
            body: node.pageContext?.content || "",
        })
    );

    const flexSearchIndex = new Index({
        preset: "performance",
        tokenize: "forward",
    });

    normalizedData.forEach(
        (doc: { id: string; path: string; pageContext: { title: string; content: string } }) => {
            const serializedDoc = JSON.stringify(
                documentIndexKeys ? pick(doc, documentIndexKeys) : doc
            );
            // Using "as number" due to FlexSearch's types, but it could technically be
            // a string as well.
            flexSearchIndex.add(doc[documentRef] as unknown as number, serializedDoc);
        }
    );

    const flexSearchStore = normalizedData.reduce(
        (acc: { [x: string]: Store }, doc: { [x: string]: IndexableDocument }) => {
            acc[String(doc[documentRef])] = documentStore ? pick(doc, documentStore) : doc;

            return acc;
        },
        {} as Store
    );

    // console.log(flexSearchStore);

    return { flexSearchIndex, flexSearchStore };
};

const useFlexSearch = (query: string, index: Index, store: Store) => {
    return useMemo(() => {
        if (!query || !index || !store) return [];

        const rawResults = index.search(query);

        const results = rawResults.map((id) => store[id]) as {
            id: string;
            path: string;
            title: string;
        }[];

        return results;
    }, [query, index, store]);
};

const SearchBar = () => {
    const { flexSearchIndex: index, flexSearchStore: store } = SearchFunctionSetup();

    // const [query, setQuery] = useState("");

    const {
        register,
        watch,
        // formState: { errors },
    } = useForm();

    const results = useFlexSearch(watch("query"), index, store);

    // console.log(results);

    const [isShow, setShow] = useState(false);

    return (
        <SearchContainer>
            <PopUpAllNyu1 className={isShow ? "open" : ""}>
                <PopUpAllNyuBtnCloseAllNyu onClick={() => setShow(false)}>
                    <FiXCircle />
                </PopUpAllNyuBtnCloseAllNyu>
                <PopUpBackDrop />
                <PopUpBackGround>
                    <SearchInputContainer>
                        <SearchInput
                            className={isShow ? "open" : ""}
                            onSubmit={(e) => {
                                e.preventDefault();
                                return;
                            }}
                        >
                            <input
                                disabled={isShow ? false : true}
                                defaultValue={""}
                                placeholder="Search"
                                autoComplete="off"
                                {...register("query")}
                            />
                        </SearchInput>
                        {results.length > 0 ? (
                            <SearchResultList className={isShow ? "open" : ""}>
                                {results.map((result) => (
                                    <SearchResultListItem key={result.id}>
                                        <Link to={result.path} onClick={() => setShow(false)}>
                                            {result.title}
                                        </Link>
                                    </SearchResultListItem>
                                ))}
                            </SearchResultList>
                        ) : null}
                    </SearchInputContainer>
                </PopUpBackGround>
            </PopUpAllNyu1>

            <SearchButton onClick={() => setShow(!isShow)}>
                <FaSearch />
            </SearchButton>
        </SearchContainer>
    );
};

const SearchContainer = styled.div`
    display: flex;
    height: 36px;
    font-size: 0.8125rem;
    line-height: 2.25rem;
`;

const SearchInput = styled.form`
    display: block;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 0 10px;

    &.open {
        opacity: 1;
    }

    input {
        height: 26px;
        width: 200px;
        padding: 5px;
    }

    @media screen and (max-width: 1038px) {
        input {
            width: 100%;
            background-color: transparent;
            color: white;
            border: none;
        }
        padding: 0;
    }
`;

const SearchInputContainer = styled.div`
    @media screen and (max-width: 1038px) {
        margin: 0 auto;
        box-sizing: border-box;
        width: 100%;
        max-width: 1024px;
        padding: 0 22px;
        padding-top: 54px;
        font-size: 1.5rem;
    }
`;

const SearchButton = styled.div`
    width: 36px;
    text-align: center;
    height: 100%;
    cursor: pointer;
    line-height: 2.4rem;

    &:hover {
        background: #57068c;
        color: white;
    }
`;

const SearchResultList = styled.ul`
    display: block;
    position: absolute;
    top: 35px;
    background-color: #fff;
    background-clip: padding-box;
    min-width: 200px;
    border-top: 5px solid transparent;
    box-shadow: 0px 8px 8px 0px rgb(0 0 0 / 75%);
    line-height: 1.5;
    max-height: 300px;
    overflow-y: auto;
    margin: 0 10px;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.open {
        opacity: 1;
    }

    @media screen and (max-width: 1038px) {
        position: relative;
        margin: 0;
        background-color: transparent;
        box-shadow: none;
        overflow-y: visible;
    }
`;

const SearchResultListItem = styled.li`
    list-style: none;
    padding: 5px;

    a {
        color: black;
        text-decoration: none;
    }
    @media screen and (max-width: 1038px) {
        a {
            color: white;
        }
    }

    &:hover {
        background-color: #57068c;

        a {
            color: white;
        }
    }
`;

const PopUpBackGround = styled.div`
    @media screen and (max-width: 1038px) {
        padding: 0;
        background: url(${allnyu_map}) 0% 0% no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        line-height: 2;
        overflow-y: scroll;
    }
`;

const PopUpBackDrop = styled.div`
    @media screen and (max-width: 1038px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(135deg, #070411 0%, #130407 100%);
        opacity: 0.98;
    }
`;

const PopUpAllNyuBtnCloseAllNyu = styled.div`
    display: none;
    @media screen and (max-width: 1038px) {
        display: block;
        position: absolute;
        top: 20px;
        right: 50px;
        color: #fff;
        font-size: 30px;
        line-height: 1;
        background: none;
        border: 0;
        z-index: 999;

        &:hover {
            color: white;
            cursor: pointer;
            transition: 0.3s;
        }
    }
`;

const PopUpAllNyu1 = styled.div`
    @media screen and (max-width: 1038px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 999999;
        pointer-events: none;
        transition: opacity 0.3s ease;
        display: block;
        opacity: 0;

        &.open {
            opacity: 1;
            pointer-events: auto;
        }
    }
`;

export default SearchBar;
