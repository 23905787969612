import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Normalize } from "styled-normalize";

const mainTheme = {
    fonts: {
        main: "Perstare var",
    },

    colors: {
        nyupurple: "#57068C",
        darkgrey: "#4B4F54",
        darkpurple: "#220337",
        blue: "#3689E1",
    },

    sizes: {
        breadcrumb: "clamp(24px, 6vw, 36px)",
        h1: "clamp(24px, 6vw, 36px)",
        h2: "clamp(16px, 6vw, 24px)",
    },
};

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Perstare";
  src: url(/fonts/NYUPerstare-VF.ttf);
}

@font-face {
  font-family: "Icomoon";
  src: url(/fonts/icomoon.ttf);
}

* {
  font-family: "Perstare";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;

`;

export default function Theme({ children }: { children: React.ReactNode }) {
    return (
        <ThemeProvider theme={mainTheme}>
            <GlobalStyles />
            <Normalize />
            {children}
        </ThemeProvider>
    );
}
