import * as React from "react";
import styled from "styled-components";
import { useState } from "react";
import { FiXCircle } from "react-icons/fi";

import allnyu_map from "@/images/allnyu_map.png";

function AllNYUOverlay() {
    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <AllNYUOverlayContent>
            <UniButton>
                <StyledButton onClick={() => setShowOverlay(true)}>
                    <NYUTorch />
                    <span>All NYU</span>
                </StyledButton>
            </UniButton>

            <PopUpAllNyu1 className={showOverlay ? "open" : ""}>
                <PopUpAllNyuBtnCloseAllNyu onClick={() => setShowOverlay(false)}>
                    <FiXCircle />
                </PopUpAllNyuBtnCloseAllNyu>
                <PopUpBackDrop />
                &nbsp;
                <PopUpGrid>
                    <UlElementGrid>
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                        <LiUlElementGrid />
                        &nbsp;
                    </UlElementGrid>
                </PopUpGrid>
                <PopUpBackGround>
                    <NyuCenters>
                        <PopUpBackGroundH2>
                            <DashLeft>NYU Around the World</DashLeft>
                        </PopUpBackGroundH2>

                        <NyuCentersUl>
                            <NyuCentersFirstChild>
                                <NyuLinksCampus href="http://nyu.edu">
                                    <NyuCentersA>New York</NyuCentersA>
                                </NyuLinksCampus>
                                <NyuLinksCampus href="http://shanghai.nyu.edu/">
                                    <NyuCentersA> Shanghai </NyuCentersA>
                                </NyuLinksCampus>
                                <NyuLinksCampus href="http://nyuad.nyu.edu/">
                                    {" "}
                                    <NyuCentersA>Abu Dhabi</NyuCentersA>
                                </NyuLinksCampus>
                            </NyuCentersFirstChild>

                            <NyuCentersLi>
                                <NyuLinksCenterA href="http://www.nyu.edu/accra">
                                    <NyuCentersA>Accra</NyuCentersA>{" "}
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/berlin">
                                    {" "}
                                    <NyuCentersA>Berlin</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/buenosaires">
                                    {" "}
                                    <NyuCentersA>Buenos Aires</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/florence">
                                    {" "}
                                    <NyuCentersA>Florence</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/london">
                                    {" "}
                                    <NyuCentersA>London</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/madrid">
                                    {" "}
                                    <NyuCentersA>Madrid</NyuCentersA>
                                </NyuLinksCenterA>
                            </NyuCentersLi>

                            <NyuCentersLi>
                                <NyuLinksCenterA href="http://www.nyu.edu/paris">
                                    <NyuCentersA>Paris</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/prague">
                                    <NyuCentersA>Prague</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/sydney">
                                    <NyuCentersA>Sydney</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/telaviv">
                                    <NyuCentersA>Tel Aviv</NyuCentersA>
                                </NyuLinksCenterA>
                                <NyuLinksCenterA href="http://www.nyu.edu/washingtondc">
                                    <NyuCentersA>Washington</NyuCentersA>
                                </NyuLinksCenterA>
                            </NyuCentersLi>
                        </NyuCentersUl>
                    </NyuCenters>

                    <NyuCentersUl>
                        <NyuSchools>
                            <PopUpBackGroundH3>
                                <DashLeft>Schools</DashLeft>
                            </PopUpBackGroundH3>

                            <NyuLinksSchoolA href="http://as.nyu.edu/">
                                <NyuCentersA>Arts and Science </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://cas.nyu.edu/">
                                <NyuCentersA>College of Arts and Science </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.nyu.edu/dental/">
                                <NyuCentersA>College of Dentistry </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://publichealth.nyu.edu/">
                                <NyuCentersA>College of Global Public Health </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://nursing.nyu.edu/">
                                <NyuCentersA>Rory Meyers College of Nursing </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.cims.nyu.edu/">
                                <NyuCentersA>
                                    Courant Institute of Mathematical Sciences{" "}
                                </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://gallatin.nyu.edu/">
                                <NyuCentersA>Gallatin School of Individualized Study </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://gsas.nyu.edu/">
                                <NyuCentersA>Graduate School of Arts and Science </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.nyu.edu/gsas/dept/fineart/">
                                <NyuCentersA>Institute of Fine Arts </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.nyu.edu/isaw/">
                                <NyuCentersA>
                                    Institute for the Study of the Ancient World{" "}
                                </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.stern.nyu.edu/">
                                <NyuCentersA>Leonard N.Stern School of Business </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.liberalstudies.nyu.edu/">
                                <NyuCentersA>Liberal Studies Program </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://wagner.nyu.edu/">
                                <NyuCentersA>Robert F.Wagner Graduate School </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.law.nyu.edu/">
                                <NyuCentersA>School of Law </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://school.med.nyu.edu/">
                                <NyuCentersA>School of Medicine </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.sps.nyu.edu/">
                                <NyuCentersA>School of Professional Studies </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://www.nyu.edu/socialwork/">
                                <NyuCentersA>Silver School of Social Work </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://steinhardt.nyu.edu/">
                                <NyuCentersA>Steinhardt School </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://engineering.nyu.edu/">
                                <NyuCentersA>Tandon School of Engineering </NyuCentersA>
                            </NyuLinksSchoolA>
                            <NyuLinksSchoolA href="http://tisch.nyu.edu/">
                                <NyuCentersA>Tisch School of the Arts </NyuCentersA>
                            </NyuLinksSchoolA>
                        </NyuSchools>
                    </NyuCentersUl>
                </PopUpBackGround>
            </PopUpAllNyu1>
        </AllNYUOverlayContent>
    );
}

const AllNYUOverlayContent = styled.div``;

export default AllNYUOverlay;

const NYUTorch = styled.i`
    font-family: "Icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: 0.3125rem;

    &:before {
        content: "\\e902";
    }
`;

const PopUpAllNyu1 = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    pointer-events: none;
    transition: opacity 0.3s ease;
    display: block;
    opacity: 0;

    &.open {
        opacity: 1;
        pointer-events: auto;
    }
`;
const PopUpAllNyuBtnCloseAllNyu = styled.div`
    position: absolute;
    top: 20px;
    right: 50px;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    background: none;
    border: 0;
    z-index: 999;

    &:hover {
        color: white;
        cursor: pointer;
        transition: 0.3s;
    }
`;
const PopUpBackDrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #070411 0%, #130407 100%);
    opacity: 0.98;
`;
const PopUpGrid = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
`;
const UlElementGrid = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
`;
const LiUlElementGrid = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
    width: 10%;
    height: 100vh;
    border-left: 1px solid rgba(255, 255, 255, 0.07);
`;
const PopUpBackGround = styled.div`
    padding: 0;
    background: url(${allnyu_map}) 0% 0% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    line-height: 2;
    overflow-y: scroll;
`;
const DashLeft = styled.span`
    position: relative;
    padding-left: 18px;
    display: inline-block;

    ::before {
        content: " ";
        width: 12px;
        height: 1px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #fff;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 20px;
    }
`;
const PopUpBackGroundH2 = styled.div`
    font-weight: bold;
    color: #fff;
    font-size: 1.5em;
    line-height: 1.90476em;
    margin-top: 0.95238em;
    margin-bottom: 0.95238em;
`;
const PopUpBackGroundH3 = styled.div`
    font-weight: bold;
    color: #fff;
    font-size: 1.17em;
    line-height: 2.442em;
    margin-top: 1.221em;
    margin-bottom: 1.221em;
`;
const NyuCenters = styled.div`
    left: 10%;
    width: 40%;
    position: absolute;
    top: 400px;
`;
const NyuCentersA = styled.span`
    display: block;
    color: #fff;
`;
const NyuCentersUl = styled.ul`
    display: flex;
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 13px;
        margin-bottom: 20px;
    }
`;
const NyuCentersLi = styled.li`
    width: 25%;
    padding-right: 10px;
    list-style: none;
`;
const NyuCentersFirstChild = styled.li`
    width: 50%;
    list-style: none;
`;
const NyuSchools = styled.div`
    left: 60%;
    position: absolute;
    top: 100px;
`;
const NyuLinksCampus = styled.a`
    font-size: 21px;
    font-weight: 300;
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 20px;
    }
`;
const NyuLinksCenterA = styled.a`
    font-size: 13px;
    font-weight: 300;
`;
const NyuLinksSchoolA = styled.a`
    font-size: 13px;
    font-weight: 300;
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 10px;
        margin-bottom: 20px;
        margin-right: 10px;
    }
`;
const StyledButton = styled.button`
    background: none;
    border: 0;
    color: #656565;
    font-size: 0.8125rem;
    padding: 0 0.5rem;
    line-height: 2.25rem;

    &:hover {
        color: white;
        cursor: pointer;
        transition: 0.3s;
    }
`;
const UniButton = styled.nav`
    float: right;
    background: white;
    height: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8px;
    color: #444444;
    font-size: 13px;
    &:hover {
        background: #57068c;
        transition: 0.3s;
        color: #ffffff;
        cursor: pointer;
    }
`;
