import React from "react";
import styled from "styled-components";

import { FaFacebookF } from "react-icons/fa";
import {
    AiOutlineTwitter,
    AiOutlineWeibo,
    AiFillYoutube,
    AiOutlineInstagram,
} from "react-icons/ai";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import footer_map from "@/images/footer_map.png";

const Footer = () => {
    return (
        <FooterContainer>
            <GlobalFooter>
                <InnerFooter>
                    <FooterDesc>
                        <FooterLogo to="/">
                            <FooterLogoImageContainer>
                                <StaticImage src="../../../images/white.png" alt="Footer Logo" />
                            </FooterLogoImageContainer>
                        </FooterLogo>

                        <FooterCallToActionTitle>Connect with NYU Shanghai</FooterCallToActionTitle>

                        <Icons>
                            <ul>
                                <li>
                                    <a href="https://twitter.com/nyushanghai/" title=" Twitter">
                                        <AiOutlineTwitter />{" "}
                                    </a>{" "}
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com/NYUShanghai/"
                                        title=" Facebook"
                                    >
                                        <FaFacebookF />
                                    </a>{" "}
                                </li>
                                <li>
                                    <a href="https://e.weibo.com/nyushanghai/" title=" Weibo">
                                        <AiOutlineWeibo />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UC8ytAYhNDxyp0EX1MjaRdHQ/featured"
                                        title=" YouTube"
                                    >
                                        <AiFillYoutube />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/nyushanghai/"
                                        title="Instagram"
                                    >
                                        <AiOutlineInstagram />
                                    </a>
                                </li>
                            </ul>
                        </Icons>
                    </FooterDesc>

                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            <ul>
                                <li>Portal Campuses</li>
                                <li>
                                    <a href="https://www.nyu.edu/">NYU</a>
                                </li>
                                <li>
                                    <a href="https://shanghai.nyu.edu">Shanghai</a>
                                </li>
                                <li>
                                    <a href="https://nyuad.nyu.edu">Abu Dhabi</a>
                                </li>
                            </ul>
                            <ul>
                                <li>Get in Touch</li>
                                <li>
                                    <a href="https://shanghai.nyu.edu/contact">Contact Us</a>
                                </li>
                                <li>
                                    <a href="http://360.shanghai.nyu.edu/">Campus Tour</a>
                                </li>
                                <li>
                                    <a href="https://shanghai.nyu.edu/about/work">Jobs</a>
                                </li>
                            </ul>
                            <ul>
                                <li>Quicklinks</li>
                                <li>
                                    <a href="https://shanghai.nyu.edu/students">Students</a>
                                </li>
                                <li>
                                    <a href="https://www.nyu.edu/footer/accessibility.html">
                                        Accessibility
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:shanghai.rits@nyu.edu">Website Feedback</a>
                                </li>
                            </ul>
                        </FooterLinkItems>
                    </FooterLinkWrapper>
                </InnerFooter>
            </GlobalFooter>
            <CopyrightFooter>© 2023 All rights reserved.</CopyrightFooter>
        </FooterContainer>
    );
};

export default Footer;

const CopyrightFooter = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.625rem 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #887d90;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
`;

const FooterContainer = styled.div`
    width: 100%;
    line-height: 1.5;
    background: #220337 url(${footer_map}) center center no-repeat;
    background-size: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
`;

const GlobalFooter = styled.div`
    padding: 3.125rem 0;
    box-sizing: inherit;
    font-size: 0.875rem;
`;

const InnerFooter = styled.div`
    max-width: 1390px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
`;

const FooterLogoImageContainer = styled.div`
    max-width: 240px;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (max-width: 991px) {
        margin: 0 auto 20px;
    }
`;

const FooterCallToActionTitle = styled.p`
    text-align: left;
`;

const FooterLogo = styled(Link)``;

const FooterDesc = styled.div`
    text-decoration: none;
    color: #fff;
    width: 40%;
    align-items: center;
    vertical-align: top;
    p {
        font-size: 14px;
        font-weight: 500;
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
`;

const Icons = styled.div`
    margin-top: 15px;
    ul li {
        list-style: none;
        display: inline-block;
        margin-right: 5px;
        list-style-image: none;
        box-sizing: inherit;
        text-align: left;
        line-height: 1.5;
    }
    a {
        border: #fff solid 1px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%;
        color: #fff;
        font-size: 1rem;
        height: 32px;
        width: 32px;
        display: block;
        text-align: center;
        line-height: 1.95;
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: inherit;
    }
`;

const FooterLinkWrapper = styled.div`
    color: #fff;
    width: 60%;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
    @media screen and (max-width: 770px) {
        display: none;
    }
`;

const FooterLinkItems = styled.div`
    display: flex;
    justify-content: space-between;

    ul li {
        list-style: none;
        font-size: 14px;
        line-height: 2em;
        &:first-child {
            margin-bottom: 15px;
            font-weight: 600;
        }
    }

    a {
        position: relative;
        z-index: 1;
        color: #fff;
        text-decoration: none;
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 400;
    }
    @media screen and (max-width: 1038px) {
        top: auto;
    }
`;
