import React from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ path, children }: { path: string; children: React.ReactNode }) {
    return (
        <>
            <Header path={path} />
            <Content>
                <main>{children}</main>
            </Content>
            <Footer />
        </>
    );
}

const Content = styled.div`
    max-width: 1390px;
    /* position: relative; */
    top: 264px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 50vh;

    @media screen and (max-width: 1440px) {
        width: 1120px;
    }

    @media screen and (max-width: 1140px) {
        width: 85%;
    }

    /* @media screen and (max-width:600px) {
          width:40%; 
        } */
`;
